import { withDirectives as _withDirectives, createVNode as _createVNode, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue";
import "core-js/modules/es.array.push.js";
import bindPermission from './bindPermission';
import { ElLoading, ElMessage } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "RequestPath",
  mixins: [mixins],
  components: {
    bindPermission
  },
  data() {
    return {
      bindPermissionDialog: false,
      currentRequestPath: null,
      // 搜索字段
      searchItems: [{
        prop: 'model',
        label: '接口模块'
      }, {
        prop: 'url',
        label: '接口地址'
      }, {
        prop: 'name',
        label: '接口名称'
      }],
      columns: [{
        prop: 'name',
        label: '接口名称',
        align: 'left'
      }, {
        prop: 'url',
        label: '接口地址'
      }, {
        label: '已绑定权限',
        customRender: props => {
          if (!props.row.url) return "";
          if (props.row.permissions) {
            const result = [];
            props.row.permissions.forEach(item => result.push(`${item.permissionName}(${item.permissionCode})`));
            return result.join();
          } else {
            return _withDirectives(_createVNode(_resolveComponent("el-button"), {
              "type": "primary",
              "link": true,
              "onClick": () => {
                this.loadPermissions(props.row);
              }
            }, {
              default: () => [_createTextVNode("\u67E5\u770B")]
            }), [[_resolveDirective("permission"), 'requestPath_permissions']]);
          }
        }
      }],
      linkButtons: [{
        name: row => {
          if (!row.url) return "";
          return '绑定权限';
        },
        disabled: row => {
          return !row.url;
        },
        code: 'requestPath_bindPermission',
        click: this.bindPermission,
        permissions: ['requestPath_bindPermission']
      }]
    };
  },
  methods: {
    bindPermission(row) {
      this.currentRequestPath = row;
      this.bindPermissionDialog = true;
    },
    doBindPermission(url, permissionIds) {
      const loading = ElLoading.service();
      this.$api.uac.requestPath.bindPermission(url, permissionIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindPermissionDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    loadPermissions(row) {
      const loading = ElLoading.service();
      this.$api.uac.permission.listByUrl(row.url).then(res => {
        if (res.code === 200) {
          row.permissions = res.data;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};