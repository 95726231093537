import "core-js/modules/es.array.push.js";
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css'

export default {
  name: "bindPermission",
  props: ['requestPath'],
  emits: ['on-close', 'on-submit'],
  data() {
    return {
      permissions: [],
      permissionIds: [],
      permissionProps: {
        multiple: true,
        checkStrictly: true,
        emitPath: false,
        value: 'permissionId',
        label: 'permissionName'
      }
    };
  },
  methods: {
    submit() {
      this.$emit('on-submit', this.requestPath.url, this.permissionIds);
    },
    cancel() {
      this.$emit('on-close');
    }
  },
  beforeMount() {
    const loading = ElLoading.service();
    Promise.all([this.$api.uac.permission.listByUrl(this.requestPath.url), this.$api.uac.permission.list()]).then(res => {
      if (res[0].code === 200 && res[1].code === 200) {
        this.permissions = res[1].data;
        if (res[0].data) {
          res[0].data.forEach(permission => this.permissionIds.push(permission.permissionId));
        }
      }
    }).finally(() => {
      loading.close();
    });
  }
};